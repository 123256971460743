import { ref } from 'vue'

// 钩子左右移动参数
export const left_hook = ref<number>(145)

// 钩子上下移动参数
export const top_hook = ref<number>(205)

// 钩子移动最小参数
export const min_hook: number = 65
// 钩子移动最大参数
export const max_hook: number = 235

// 钩子每次移动的距离
export const long: number = 22

// 礼物口礼物的移动
export const liwu_translate = ref<number>(-90)

/**
 * @description 钩子上的礼物是否显示或者隐藏
 * @param 0隐藏 1显示
 */
export const _opacity = ref<number>(0)

/**
 * @description 定时函数
 */
export const setTimeGame = (fun: () => void, time: number) => {
    setTimeout(() => {
        fun()
    }, time || 500)
}

/**
 * @desc ---------------转盘参数--------------
 */

export const _rotate = ref<number>(-28)

/**
 *
 * @param n 最小值
 * @param m 最大值
 * @returns 随机生成 n-m之间的正整数
 */
const getRandom = (n: number, m: number) => Math.floor(Math.floor(Math.random() * (m - n + 1) + n))

/**
 * @desc 生成0-100之间的数，根据数字的大小和概率区间控制概率大小
 * @概率区间 例如100积分中奖概率为10%， 区间在生成0-100（40-50）之间
 */
let caseNum: number = -1
let _prize: string = ''
export const startPlay = () => {
    let number: number = getRandom(0, 100)
    if (number === -1) {
        caseNum = 0 //概率为0% 耳机键盘
    } else if (number > -2 && number < 0) {
        caseNum = 1 //概率为0% 耳机键盘
    } else if (number >= 0 && number < 20) {
        caseNum = 2 // 18积分中奖为20%
    } else if (number >= 20 && number < 40) {
        caseNum = 3 // 35积分中奖为20%
    } else if (number >= 40 && number < 50) {
        caseNum = 4 // 100积分中奖为10%
    } else {
        caseNum = 5 // 未中奖为50%
    }
    switch (caseNum) {
        case 0:
            ratating(360 - 0, '机械键盘')
            break
        case 2:
            ratating(360 - 60, '18积分')
            break
        case 5:
            ratating(360 - 120, '感谢参与')
            break
        case 4:
            ratating(360 - 180, '100积分')
            break
        case 1:
            ratating(360 - 240, '蓝牙耳机')
            break
        case 3:
            ratating(360 - 300, '35积分')
            break
    }
}
const isGo = ref<boolean>(false)
const lastDeg = ref<number>(0)
const ratating = (deg: number, text: string) => {
    isGo.value = true
    // 中奖名称
    _prize = text
    /* -@params realDeg =>-上次指针离初始状态的度数 + 上次的度数 + 这次要旋转的度数
    (这样的目的是为了每次旋转都从原点开始，保证数据准确)*/
    let times = getRandom(4, 6) //圈数(从用户体验角度考虑，设计随机转3-6圈。最少3圈，最多6圈)
    let randomDeg = deg + 360 * times //记录这次要旋转的度数(传来的度数+圈数)
    let realDeg = 360 - (lastDeg.value % 360) + lastDeg.value + randomDeg
    _rotate.value = realDeg
    lastDeg.value = realDeg //把这次度数存储起来，方便下一次获取
}

/**
 *
 * @param timer 转盘转动时间，与css -- transition: all 10s; 保持一致
 */
export const playEnd: any = (timer?: number) => {
    return new Promise((resolve, reject) => {
        resolve({
            caseNum,
            _prize
        })
    })
}
