import http from '../utils/request'

interface CodeOutDto {
    user_id: number,
    name: string
}

/**
 * @desc 扣除积分
 */
export const codeOut = (data: CodeOutDto) => {
    return http.request({
        url: '/auth/user/code/out',
        method: 'post',
        data
    })
}

export const addCode = (data: CodeOutDto) => {
    return http.request({
        url: 'auth/user/signin',
        method: 'post',
        data
    })
}

/**
 * 娃娃机兑换
 */
export const conversionEntity = (data: Record<string, any>) => {
    return http.request({
        url: 'conversion/entity/coversion',
        method: 'post',
        data
    })
}